.list {
    min-width: 33%;
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 600px) {
    .list {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
    }

    .tech-item {
        padding: .25em .5em;
        margin: 0 .5em .5em 0;
    }
}