.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
}

.nav-left {
  display: flex;
  flex-direction: left;
  justify-content: left;
  align-items: center;
}

.nav-right {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.nav-bar a {
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  color: #b4abab
}

.nav-bar a:hover {
  opacity: 0.5;
  cursor: pointer;
}

.nav-bar a.active {
  color: #b4abab;
  opacity: 0.5;
}

.nav-bar a:visited{
  color: #b4abab;
}

.burger {
  padding: 5px 10px;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .nav-left {
    justify-content: left;
    align-items: left;
  }

  .links {
    display: flex;
    flex-direction: column;
    font-size: 4em;
    align-items: flex-start
  }
}