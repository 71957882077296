.App {
  background-color: #000000;
  color: #b4abab;
  width: 100%;
}

.sections {
  justify-content: left;
  width: 60%;
}

.section-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  font-size: calc(10px + 1.5vmin);
}

.text-column {
  margin: 0;
  padding: 1em 2em;
  width: 100%;
  justify-content: left;
}

h1, h2 {
  font-size: 2em;
  margin: 0 0 0.5em 0;
}

p {
  margin: 0;
}

.body-text {
  margin: 0 0 1em 0;
}

.parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

