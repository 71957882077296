.row-container {
    display: flex;
    flex-direction: row;
    gap: 3em;
}

.skills {
    display: flex;
    flex-wrap: wrap;
}

.sub-title {
    font-size: 1em;
}

@media only screen and (max-width: 768px) {
    .skills-container {
     flex-direction: column;
    }
}